body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

ul.mainnav {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: #333;
}

ul.mainnav li {
	float: left;
}

ul.mainnav li a {
	display: block;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

/* li:last-child a {
	display: block;
	color: yellowgreen;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	right: 0;
	position: absolute;
} */

ul.mainnav li:last-child {
	color: yellowgreen;
	background-color: gray;
	text-decoration: none;
	right: 0;
	position: absolute;
}

ul.mainnav li a span {
	display: block;
	color: white;
	text-align: center;
	margin: 0px 30px;
	text-decoration: none;
	font-weight: bold;
}

ul.mainnav li a:hover {
	background-color: #111;
}

.mainContainer {
	width: 80%;
	margin: auto;
	padding: 10px;
}

.loginContainer {
	width: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
}

/* ul.innerMenu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 25%;
	background-color: #f1f1f1;
	position: fixed;
	height: 100%;
	overflow: auto;
} */

ul.innerMenu {
	list-style-type: none;
    margin: 0;
    padding: 0;
    width: 25%;
    background-color: #f1f1f1;
    position: static;
    height: 500px;
    overflow: auto;
    float: left;
}

ul.innerMenu li a {
	display: block;
	color: #000;
	padding: 8px 16px;
	text-decoration: none;
}

ul.innerMenu li a.active {
	background-color: #04aa6d;
	color: white;
}

ul.innerMenu li a:hover:not(.active) {
	background-color: #555;
	color: white;
}

/* ul.innerMenu li:last-child {
	color: yellowgreen;
	background-color: gray;
	text-decoration: none;
	bottom: 0;
	position: absolute;
} */

div.innerDiv {
	margin-left: 25%;
	padding: 1px 16px;
	height: 1000px;
}
